<template>
  <v-expansion-panels accordion>
    <v-expansion-panel
        v-for="(item,i) in questions"
        :key="i"
    >
      <v-expansion-panel-header><strong>{{ item.question }}</strong></v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-html="item.answer"></div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  data: () => ({
    questions: [
      {question: 'Welche Informationen erhalte ich in dem Portal und warum sollte ich es nutzen?', answer: '<p>Das Portal bietet Ihnen Zugang zu den unterjährigen Verbrauchsinformationen (UVI) für Wasser und Heizenergie in Ihrer Wohnung. Voraussetzung dafür ist das Vorhandensein entsprechender fernabfragefähiger Zählervorrichtungen (z.B. Heizkostenverteiler) und deren Abrechnung über eine Heiz- und Betriebskostenabrechnung durch Ihren Vermieter.</p>Ihre Vorteile:<ul><li>Ihren Verbrauch monatlich genau im Blick behalten</li><li>Vergleich Ihres Verbrauchs mit dem Vorjahr</li><li>Detaillierte Informationen über Ihr Nutzungsverhalten</li><li>Nützliche Tipps rund ums Sparen von Wasser und Wärme</li></ul>'},
      {question: 'Wie kann ich das Portal einrichten?', answer: '<p>Sie erhalten die Anmeldedaten zum Verbrauchsportal von Ihrem Vermieter über die ihm bekannte E-Mailadresse. Sollte Ihr Vermieter keine E-Mailadresse von Ihnen hinterlegt haben, kontaktieren Sie bitte Ihren Vermieter.</p>'},
      {question: 'Wie erfolgt die Registrierung?', answer: '<p>Nach erfolgreicher Anlage durch Ihren Vermieter erhalten Sie einen Aktivierungslink für das Verbrauchsportal per E-Mail. Bitte prüfen Sie auch Ihren SPAM/Unbekannt-Ordner. Alternativ können Sie auch über die Funktion „Passwort vergessen“ ein Passwort anfordern. Ist dies nicht möglich, kontaktieren Sie bitte zunächst Ihren Vermieter.</p><p>Bitte beachten Sie auch unsere <a href="/assets/documents/Anleitung-Erstanmeldung-im-Verbrauchsportal.pdf" target="_blank">Schritt-für-Schritt-Anleitung Erstanmeldung im Verbrauchsportal</a>.</p>'},
      {question: 'Ich habe mein Passwort oder meine E-Mailadresse vergessen. Wie bekomme ich wieder Zugang zum Portal?', answer: '<p>Um das Passwort zum Verbrauchsportal zurückzusetzen, öffnen Sie bitte hierzu das Portal in Ihrem Browser. Den Link erhalten Sie von Ihrem Vermieter.</p><p>Klicken Sie unter der Login Funktion auf die Schaltfläche „Passwort vergessen“.</p><p>Nun ist Ihre E-Mail-Adresse einzugeben. Diese muss identisch mit der E-Mail-Adresse sein, die Sie ihrem Vermieter/Verwalter mitgeteilt haben. Sie erhalten nach Klick auf die Schaltfläche „Passwort zurücksetzen“ ein automatisches Passwort via Mail. Anschließend können Sie ihr neues persönliches Passwort vergeben.</p><p>Falls Sie keinen Zugang mehr zu Ihrer im Verbrauchsportal hinterlegten E-Mail Adresse haben, kontaktieren Sie bitte mit einer alternativen Mailadresse Ihren Vermieter. Dieser muss die im Portal hinterlegte E-Mailadresse anpassen.</p>'},
      {question: 'Passwort mehrfach falsch eingegeben, Login gesperrt - wie entsperre ich den Zugang?', answer: '<p>Haben Sie Ihren Zugang aufgrund von Falschanmeldung gesperrt, können Sie über die Funktion „Passwort vergessen“ jederzeit ein neues Passwort anfordern und so Ihren Zugang wieder zurücksetzen.</p>'},
      {question: 'Anmeldung mit zugesandtem Link nicht möglich', answer: '<p>Zur Anmeldung im Verbrauchsportal haben Sie einen Link per E-Mail erhalten. Ist die Anmeldung über diesen Link nicht möglich, können Sie jederzeit über die Funktion „Passwort vergessen“ ein neues Passwort per E-Mail anfordern. Diese muss identisch mit der E-Mail-Adresse sein, die Sie Ihrem Vermieter/Verwalter mitgeteilt haben.</p>'},
      {question: 'Mein Aktivierungslink nach Registrierung ist abgelaufen, wie erhalte ich einen neuen?', answer: '<p>Der Aktivierungslink zur abschließenden Bestätigung Ihrer Anmeldung ist nur begrenzt gültig. Um einen neuen Aktivierungslink zu erhalten, wählen Sie bitte die Funktion „Passwort vergessen“ und fordern Sie ein neues Passwort an. Diese muss identisch mit der E-Mail-Adresse sein, die Sie Ihrem Vermieter/Verwalter mitgeteilt haben.</p>'},
      {question: 'Mindestanforderungen für das Passwort (Buchstaben, Sonderzeichen, Ziffern etc.)', answer: '<p>Das Passwort muss mindestens folgende Anforderungen erfüllen:</p><ul><li>mindestens 8 Zeichen</li><li>mindestens 1 Großbuchstabe</li><li>mindestens 1 Kleinbuchstabe</li><li>mindestens 1 Zahl</li><li>mindestens 1 Sonderzeichen</li></ul>'},
      {question: 'Ich möchte mein Passwort oder meine E-Mailadresse ändern. Wo kann ich das tun?', answer: '<p>Sie können die Änderung Ihrer Anmeldedaten im Portal über die Seite „Anmeldedaten“ auf dem Profilicon oben rechts vornehmen. </p>'},
      {question: 'Können die anderen Mieter meine Daten ebenfalls einsehen?', answer: '<p>Selbstverständlich sind Ihre persönlichen Verbrauchsdaten nur Ihnen zugänglich und können nicht von anderen eingesehen werden.</p>'},
      {question: 'Ich möchte meine Raumbezeichnung ändern, welche Auswirkungen hat dies?', answer:'<p>Sie können jederzeit innerhalb des Portals die Bezeichnung des Raumes, zu dem die hinterlegten Zählernummern zugeordnet sind, ändern. Die Möglichkeit dazu haben Sie auf der Seite „Heizenergie“ und „Wasserverbrauch“ über das Zahnrad-Symbol.  Die Änderung betrifft nur die Anzeige im Verbrauchsportal und hat keine Auswirkungen auf die jährliche Abrechnung. Bitte nutzen Sie hier im Zweifel die Zählernummer zur Identifikation, sollte die Raumbezeichnung nicht mit der tatsächlichen Nutzung in Ihrer Wohnung übereinstimmen.</p>'},
      {question: 'Kann ich die angezeigten Werte ausdrucken/speichern?', answer: '<p>Sie haben im Portal bis zu zwei Jahre Zugriff auf Ihre Verbrauchsdaten (beginnend Januar 2022), wählen Sie dazu ganz einfach in der Übersichtsgrafik den passenden Vergleichszeitraum aus. Für die Zählerstandsdaten der letzten 12 Monate ist zusätzlich ein Excel-Export möglich. Wählen Sie dazu auf der Seite Heizenergie und Wasserverbrauch die passenden Buttons unterhalb der Tabelle zur Einzelwertanzeige. Voraussetzung ist, dass Ihre Wohnung bereits so lange mit fernabfragefähiger Messtechnik ausgestattet ist.</p>'},
      {question: 'Welche Möglichkeiten habe ich unterjährig meinen Verbrauch zu reduzieren?', answer: '<p>Nützliche Tipps zum Sparen von Wasser und Heizenergie erhalten Sie auf dem Dashboard in Ihrem Verbrauchsportal.</p>'},
      {question: 'Keine Vorjahreswerte - warum werden mir die Vorjahreswerte nicht angezeigt, obwohl die Funktion angegeben ist?', answer: '<p>Voraussetzung ist, dass Ihre Wohnung bereits so lange mit fernabfragefähiger Messtechnik ausgestattet ist. Aus Datenschutzgründen können Ihnen zudem nur Verbrauchswerte für einen Zeitraum angezeigt werden, in dem Sie selbst die Wohnung bewohnt haben. War dies im Vorjahr nicht der Fall, können Ihnen leider keine Vorjahreswerte zum Vergleich angezeigt werden. Werden Ihnen innerhalb des von Ihnen bewohnten Zeitraumes nicht alle Daten korrekt angezeigt, prüfen Sie bitte die Verbrauchsanzeige im Portal auf einen Hinweis zum ausgewählten Monat.</p>'},
      {question: 'Ich habe mehrere Wohnungen gemietet, bekomme ich mehrere Zugänge zum Portal?', answer: '<p>Nein, Sie können bequem auf all Ihre Verträge bei Ihrem Vermieter über einen Zugang zugreifen. Sie können innerhalb des Portals über das Menü zwischen den Ansichten je Vertrag wechseln (ändern). Voraussetzung ist, dass die E-Mail-Adresse, die Sie dem Verwalter mitgeteilt haben, für beide Wohnungen identisch ist.</p>'},
      {question: 'Ich bekomme bei der Nutzung des Portals eine Fehlermeldung. Bei erneutem Login taucht dieser Fehler auch weiterhin auf. Was kann ich tun?', answer: '<p>Das Portal ist für die neusten Versionen der gängigen Web Browser ausgelegt. Bitte prüfen Sie bei diversen Fehlermeldungen (Fehler 404 z.B.), ob Updates für Ihren Browser erforderlich sind. Sollten Sie die aktuellste Browserversion nutzen, raten wir Ihnen einen anderen Browser zu verwenden.</p>'},
      {question: 'Ich benötige weitere Hilfe', answer: '<p>Bitte kontaktieren Sie bei Fragen zum Umgang mit dem Portal sowie bei Fragen zu dargestellten Werten Ihren Vermieter.</p>'},
    ]
  })
}
</script>
